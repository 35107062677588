import React from "react";
import Trip from "./Trip"; // Make sure the path to the Trip component is correct
import Trips from "./Trips";

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tripId = urlParams.get('id');

  if (tripId) {
    // Render the Trip component when tripId is present
    return <Trip id={tripId} />;
  } else {
    // Render the TripS component when tripId is not present
    return <Trips />;
  }
}

export default App;
