import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { getTrip } from "./api/apiHandler";
import DayButton, { CardView } from "./TripView";
import MapScreen from "./MapView";
import './trip.css';

function Trip(params) {
  // Use the useParams hook to get the tripId from the URL
  const [trip, setTrip] = useState(null);
  const [selectedDayOption, setSelectedDayOption] = useState(0);

  useEffect(() => {
    // Use the tripId to fetch the trip data from the API
    async function loadTrip() {
      const trip = await getTrip(params.id);
      setTrip(trip);
    }
    loadTrip();
    }, [params]);

  const getCityLocations = () => {
    const locations = [];
    for (let i = 0; i < trip.cities.length; i++) {
      const city = trip.cities[i];
      const cityLocation = city.geometry?.location;
      const locationObject = {
        geometry: {
          location: cityLocation
        },
        name: city.name,
        placeId: city.place_id
      }
      locations.push(locationObject);
    }
    return locations;
  }

  const changeSelectedDay = (index) => {
    setSelectedDayOption(index);
  };

  const defaultImage = trip?.city?.photoUrl; // Provide the default image URL

  if (!trip) {
    // If the trip is not available, you can render a loading message or a placeholder
    return <div>Loading trip data...</div>;
  }
  if (trip.cities) {
    return (
      <div>
      <div style={styles.bannerContainer}>
        <MapScreen cityLocation={trip.cities[0].geometry?.location} itinerary={getCityLocations()} />
      </div>
    <div style={multiCityStyles.container}>
    <div>
      {!_.isEmpty(trip) && (
        trip.cities && trip.cities.map((city, index) => (
          <div key={index}>
          <div style={multiCityStyles.item}>
            <div>
              <p style={multiCityStyles.city}>{index + 1}. {city.name}, {city.country}</p>
              <p style={{ color: 'gray' }}>{trip.days[index]?.days} days</p>
            </div>
          </div>
        <div style={{ borderBottomWidth: 1, borderBottomColor: '#ccc' }}>
            <div>
              {trip.itinerary && trip.itinerary.length > 0 && trip.itinerary[selectedDayOption] && (
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {trip?.itinerary[index]?.itinerary[0]?.map((item, index) => (
                        <div key={index}>
                          <img src={item.photoUrl || defaultImage} style={multiCityStyles.image} />
                        </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        <div>
        </div>
        </div>
        ))
      )
    }
    </div>
    </div>
    </div>)
  }

  return (
    <div>
    {trip &&
    <div>
      <div style={styles.bannerContainer}>
      {trip.itinerary && trip.itinerary.length > 0 && <MapScreen cityLocation={trip.city?.geometry?.location} itinerary={trip.itinerary[selectedDayOption]} />}
      </div>
      <div style={styles.container}>
      <div style={styles.nameContainer}>
        <span style={styles.title}>{trip.city?.name}, {trip.country}</span>
      </div>
      <div style={styles.infoContainer}>
        <a href="https://play.google.com/store/apps/details?id=com.citytrip.ai">
          <img src="./google-play.png" className="downloadImage responsive-image" alt="download-google-play" />
        </a>
        <a href="https://apps.apple.com/us/app/citytrip-ai/id6459511407">
        <img src="./appstore.png" className="downloadImage responsive-image" alt="download-apple-store" />
        </a>
      </div>
      <span style={{color: 'grey'}}>{trip.days} days, {trip.companion?.toLowerCase()}</span> 
      <DayButton
        itinerary={trip.itinerary}
        selectedIndex={selectedDayOption}
        onSelect={changeSelectedDay}
      />
      {trip.itinerary && trip.itinerary.length > 0 && trip.itinerary[selectedDayOption] && (
        <div>
            {trip.itinerary[selectedDayOption].map((item, index) => (
                <div key={index}>
                    <CardView name={item.name} text={item.text} additional={item.additional} image={item.photoUrl} rating={item.rating} hour={item.hour} defaultImage={defaultImage} googleUrl={item.googleUrl} />
                </div>
            ))}
        </div>
      )}
      <a href="https://play.google.com/store/apps/details?id=com.citytrip.ai">
        <img src="./google-play.png" style={styles.downloadImage} alt="download-google-play" />
      </a>
      <a href="https://apps.apple.com/us/app/citytrip-ai/id6459511407">
        <img src="./appstore.png" style={styles.downloadImage} alt="download-apple-store" />
      </a>
    </div>
    </div>}
    {/* Banner for promoting the app */}
    <div style={styles.appBanner}>
        <b style={styles.appBannerText}>Someone shared this trip with you, <br /> if you want to add it to your trips open the app.</b>
          <button style={styles.appButton} onClick={() => window.open(`citytrip.ai://trip/${trip._id}`, '_blank')}>
            <p style={styles.appButtonText}>Open the App</p>
          </button>
      </div>
    </div>
  );
};

const styles = {
  bannerContainer: {
    height: 300,
    width: '100%', // Set to full width
    backgroundColor: '#f0f0f0', // Example background color
    display: 'flex',
    justifyContent: 'center', // Center the content horizontally
    alignItems: 'center', // Center the content vertically
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the cards horizontally
    maxWidth: '700px', // Set a maximum width for the container
    margin: 'auto', // Center the container horizontally (assuming it's inside a parent container)
    padding: '20px', // Add some padding to the container
  },
    title: {
      marginTop: 15,
      fontSize: 25,
      fontWeight: 'bold',
    },
    titleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    nameContainer: {
      paddingTop: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoContainer: {
      borderRadius: 16,
      marginLeft: 'auto',
      position: 'absolute',
      right: 0,
      padding: 10,
    },
    downloadImage: {
      height: 50,
    },
    button: {
      backgroundColor: 'black',
      padding: 12,
      marginTop: 10,
      borderRadius: 8,
      cursor: 'pointer', // Show pointer cursor on hover
    },
    buttonText: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 24,
      margin: 0, // Remove margin to center text within the button
    },
  // Styles for the app promotion banner
  appBanner: {
    backgroundColor: 'rgb(22, 160, 133)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '8px',
    position: 'fixed',
    bottom: '0',
    width: '100%',
  },
  appBannerText: {
    color: 'white',
    marginRight: '10px',
    fontSize: '16px',
  },
  appButton: {
    backgroundColor: 'white',
    padding: '12px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  appButtonText: {
    color: 'rgb(22, 160, 133)',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '0',
  },
};

const multiCityStyles = {
  image: {
    height: '100px',
    width: '100px',
    objectFit: 'cover',
    borderRadius: '10px',
    margin: '10px'
  },
  item: {
    flexDirection: 'column', // Change this to 'column'
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px solid #ccc',
    marginTop: '20px',
  },
  number: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  city: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  container: {
    flex: '1',
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
  },
  nameContainer: {
    paddingTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '20px',
    paddingBottom: '10px',
  },
};

export default Trip;
