import React from 'react';
import { MdAccessTime, MdCalendarMonth } from 'react-icons/md';
import { FaStar } from 'react-icons/fa';

export const HomeCardView = ({ id, city, country, days, image, text }) => {
  return (
    <div style={aicardstyles.card}>
      <img src={image} style={homeCardstyles.image} alt="Activity image" />
      <div style={aicardstyles.cardContent}>
        <div style={aicardstyles.nameContainer}>
          <p style={aicardstyles.title}>{city}, {country}</p>
          <div style={aicardstyles.textIconContainer}>
            <MdCalendarMonth name="calendar" size={20} color="lightgrey" />
            <p style={aicardstyles.rating}>{days}</p>
            </div>
        </div>
        <p style={aicardstyles.text}>{text.substring(0,45)}...</p>
        <a href={`https://platform.citytrip-ai.com/?id=${id}`}>
        <button style={aicardstyles.button}>
          <p style={aicardstyles.buttonText}>Open</p>
        </button>
        </a>
      </div>
    </div>
  );
};

export const CardView = ({ name, image, rating, hour, defaultImage, googleUrl, text, additional }) => {
  return (
    <div style={aicardstyles.card}>
      <img src={image || defaultImage} style={aicardstyles.image} alt="Activity image" />
      <div style={aicardstyles.cardContent}>
        <div style={aicardstyles.nameContainer}>
          <p style={aicardstyles.title}>{name}</p>
          {hour && <div style={aicardstyles.textIconContainer}>
          <MdAccessTime size={18} /> <p style={aicardstyles.iconText}>{hour}</p>
        </div>}
        </div>
        {rating && <div style={aicardstyles.textIconContainer}>
            <FaStar size={18} color="#FFC107" />
            <p style={aicardstyles.rating}>{rating}</p>
          </div>}
        <p style={aicardstyles.text}>{text}</p>
        {googleUrl && 
        <button style={aicardstyles.button} onClick={() => window.open(googleUrl, '_blank')}>
          <p style={aicardstyles.buttonText}>View on map</p>
        </button>}
      </div>
    </div>
  );
};

const homeCardstyles = {
  image: {
    resizeMode: 'cover',
    borderRadius: 10,
    width: 600,
  }
};

const aicardstyles = {
  card: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    margin: '10px', // Increase the margin to add more space between the cards
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  image: {
    width: '100%',
    height: 150,
    objectFit: 'cover', // Ensure the image scales properly within the container
  },
  cardContent: {
    padding: '15px', // Reduce padding to reduce white space
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  text: {
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#6495ED',
    padding: 10,
    marginTop: 10,
    borderRadius: 8,
    cursor: 'pointer', // Show pointer cursor on hover
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0, // Remove margin to center text within the button
  },
  textIconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Spread the name and rating evenly in the container
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rating: {
    marginLeft: 5,
    fontSize: 14,
    color: 'grey',
    margin: 0, // Remove margin to ensure the rating is close to the icon
  },
};


const DayButton = ({ itinerary, selectedIndex, onSelect }) => {
  return (
    <div style={styles.buttonContainer}>
      {itinerary &&
        Array.isArray(itinerary) &&
        itinerary.map((element, index) => (
          <button
            key={index}
            style={
              // Step 3: Conditionally apply extra style if the button is selected
              index === selectedIndex
                ? { ...styles.button, ...styles.selectedButton }
                : styles.button
            }
            onClick={() => onSelect(index)}
          >
            <span
              style={
                // Step 3: Conditionally apply extra style if the button is selected
                index === selectedIndex
                  ? { ...styles.buttonText, ...styles.selectedButtonText }
                  : styles.buttonText
              }
            >
              Day {index + 1}
            </span>
          </button>
        ))}
    </div>
  );
};

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    paddingLeft: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    padding: '8px 20px',
    marginRight: 10,
    marginBottom: 10,
    border: 'none',
    cursor: 'pointer',
  },
  selectedButton: {
    backgroundColor: '#6495ED',
  },
  selectedButtonText: {
    color: 'white',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
};

export default DayButton;
