import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import _ from 'lodash';

const customIcon = new L.Icon({
  iconUrl: 'https://icons.iconarchive.com/icons/steve/zondicons/256/Location-icon.png', // Replace this URL with the URL of your custom marker image
  iconSize: [32, 32], // Adjust the icon size as needed
  iconAnchor: [16, 32], // Adjust the anchor point if needed (default is the bottom middle of the icon)
  popupAnchor: [0, -32], // Adjust the popup anchor if needed (default is the top middle of the icon)
});

const MapScreen = ({ cityLocation, itinerary }) => {
  const [locationObjects, setLocationObjects] = useState([]);

  useEffect(() => {
    // Fetch coordinates for the five places and set them in the state
    const fetchCoordinates = async () => {
      setLocationObjects([]);
      // Fetch coordinates for each place and add them to the coordinates array
      itinerary.forEach(async (place) => {
        const c = _.get(place, 'geometry.location');
        if (c) {
          const obj = { lat: c.lat, lng: c.lng, name: place.name, placeId: place.placeId };
          setLocationObjects((prevCoordinates) => [...prevCoordinates, obj]);
        }
      });
    };
    fetchCoordinates();
  }, [itinerary]);

  return (
    <MapContainer
      center={[cityLocation.lat, cityLocation.lng]}
      zoom={13}
      style={{ width: '100%', height: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {locationObjects && locationObjects.map((locationObject, index) => (
        <Marker
          key={index}
          position={[locationObject.lat, locationObject.lng]}
          icon={customIcon}
        >
          <Popup>
            <div>
              <strong>{locationObject.name}</strong>
            </div>
          </Popup>
        </Marker>
      ))}

      {locationObjects && locationObjects.length > 1 && (
        <Polyline
          positions={locationObjects.map(location => [location.lat, location.lng])}
          color="grey"
          dashArray={[10, 10]}
        />
      )}
    </MapContainer>
  );
};

export default MapScreen;
