import React, { useEffect, useState } from "react";
import { getExampleTrips } from "./api/apiHandler";
import { CardView, HomeCardView } from "./TripView";
import MapScreen from "./MapView";
import './trip.css';

function Trips(params) {
  // Use the useParams hook to get the tripId from the URL
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    // Use the tripId to fetch the trip data from the API
    async function loadTrip() {
      const trips = await getExampleTrips();
      setTrips(trips);
    }
    loadTrip();
    }, [params]);

  if (!trips) {
    // If the trip is not available, you can render a loading message or a placeholder
    return <div>Loading trip data...</div>;
  }

  return (
    <div>
    {trips &&
    <div>
      <div style={styles.container}>
      <div style={styles.nameContainer}>
        <span style={styles.title}>Welcome to Citytrip.AI!</span>
      </div>
      <div style={styles.infoContainer}>
        <a href="https://play.google.com/store/apps/details?id=com.citytrip.ai">
          <img src="./google-play.png" className="downloadImage responsive-image" alt="download-google-play" />
        </a>
        <a href="https://apps.apple.com/us/app/citytrip-ai/id6459511407">
        <img src="./appstore.png" style={styles.downloadImage} alt="download-apple-store" />
      </a>
      </div>
      {trips && (
        <div>
            {trips.filter(trip => trip.city).map((item, index) => (
                <div key={index}>
                  <HomeCardView
                    id={item._id}
                    city={item.city.name}
                    country={item.country}
                    days={item.days}
                    image={item.city.photoUrl}
                    maxBudget={item.maxBudget}
                    companion={item.companion}
                    text={item.raw}
                  />
                </div>
            ))}
        </div>
      )}
      <a href="https://play.google.com/store/apps/details?id=com.citytrip.ai">
        <img src="./google-play.png" style={styles.downloadImage} alt="download-google-play" />
      </a>
      <a href="https://apps.apple.com/us/app/citytrip-ai/id6459511407">
        <img src="./appstore.png" style={styles.downloadImage} alt="download-apple-store" />
      </a>
    </div>
    </div>}
    </div>
  );
};

const styles = {
  bannerContainer: {
    height: 300,
    width: '100%', // Set to full width
    backgroundColor: '#f0f0f0', // Example background color
    display: 'flex',
    justifyContent: 'center', // Center the content horizontally
    alignItems: 'center', // Center the content vertically
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the cards horizontally
    maxWidth: '700px', // Set a maximum width for the container
    margin: 'auto', // Center the container horizontally (assuming it's inside a parent container)
    padding: '20px', // Add some padding to the container
  },
    title: {
      marginTop: 15,
      fontSize: 25,
      fontWeight: 'bold',
    },
    titleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    settingsbutton: {
      borderRadius: 16,
      marginLeft: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      padding: 10,
    },
    mapButton: {
      borderRadius: 16,
      marginLeft: 'auto',
      position: 'absolute',
      right: 30,
      top: 0,
      padding: 10,
    },
    buttonContent: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoButton: {
      backgroundColor: '#6495ED',
      padding: 10,
      marginTop: 10,
      borderRadius: 8,
    },
    nameContainer: {
      paddingTop: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoContainer: {
      borderRadius: 16,
      marginLeft: 'auto',
      position: 'absolute',
      right: 0,
      padding: 10,
    },
    downloadImage: {
      height: 50,
    }
  };

export default Trips;
