const backendUrl = 'https://cityapp-urhg.onrender.com';

export const getTrip = async (id) => {
    try {
      const response = await fetch(`${backendUrl}/trips/${id}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
}

 export const getExampleTrips = async () => {
  try {
    const res = await fetch(`${backendUrl}/trips`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: 'yarnouts@gmail.com'
      })
    });
    return res.json();
  } catch (error) {
    console.error(error);
  }
};